import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
//import { useSelector } from 'react-redux'
import dayjs from "dayjs";

import CustomForm from "../../CustomForm";
import PermissionsControl from "../../PermissionsControl";
import MapPolygonInput from "../../form_elements/MapPolygonInput";
import TextInput from "../../form_elements/TextInput";
import TextAreaInput from "../../form_elements/TextAreaInput";
import RadioGroupInput from "../../form_elements/RadioGroupInput";
import { AlertTypeSelector } from "../../alertIconSelector/AlertIconSelector";

const alertOptions = [
  {
    value: "3",
    label: AlertTypeSelector("Tempestade"),
    icon: "tempestade",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "4",
    label: AlertTypeSelector("Deslizamento"),
    icon: "landslide",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "5",
    label: AlertTypeSelector("Alagamento"),
    icon: "flooding",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
];

const levelOptions = [
  {
    value: 4,
    label: "alerts.riskLevel.veryHight",
    icon: "very_high_risk",
    uncheckedStyle: "c-radio-button c-radio-button--very-high-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-vh-risk",
  },
  {
    value: 3,
    label: "alerts.riskLevel.hight",
    icon: "high_risk",
    uncheckedStyle: "c-radio-button c-radio-button--high-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-high-risk",
  },
  {
    value: 2,
    label: "alerts.riskLevel.medium",
    icon: "medium_risk",
    uncheckedStyle: "c-radio-button c-radio-button--medium-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-medium-risk",
  },
  {
    value: "1",
    label: "alerts.riskLevel.low",
    icon: "low_risk",
    uncheckedStyle: "c-radio-button c-radio-button--low-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-low-risk",
  },
];

function AlertsForm() {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());
  const categories = ["AlertData"];
  //TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
  //const isInsideBoundary = useSelector(state => state.formValidation.isInsideBoundary)

  function getInitialValues(item) {
    let initialValues = {
      location: item?.location || "",
      title: item?.title || "",
      id_alert: String(item?.id_alert || ""),
      id_level: String(item?.id_level || ""),
      description: item?.description || "",
    };

    return initialValues;
  }

  function formValidation(values) {
    let errors = {};

    if (!values.location) {
      errors.location = t("common.inputErrors.fieldRequired");
    }
    //TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
    /*else if (values.location && !isInsideBoundary) {
            errors.location = t('common.inputErrors.outOfBounds')
        }*/

    if (!values.title) {
      errors.title = t("common.inputErrors.fieldRequired");
    }

    if (!values.id_alert) {
      errors.id_alert = t("common.inputErrors.fieldRequired");
    }

    if (!values.id_level) {
      errors.id_level = t("common.inputErrors.fieldRequired");
    }

    if (!values.description) {
      errors.description = t("common.inputErrors.fieldRequired");
    }

    return errors;
  }

  function formatSubmitValues(values) {
    let submitValues = {
      form: values,
      requestUrl: itemId ? `api/weather/alert/${itemId}` : "api/weather/alert",
      method: itemId ? "patch" : "post",
    };

    return submitValues;
  }

  useEffect(() => {
    document.title = itemId
      ? t("alerts.pageTitle.edit")
      : t("alerts.pageTitle.create");
  });

  return (
    <div className="c-main-content c-main-content--form">
      <PermissionsControl role="weather" type="componentPage" action="update">
        <CustomForm
          params={{
            formType: "alerts",
            categories,
            getInitialValues,
            formValidation,
            formatSubmitValues,
            requestData: {
              requestUrl: "api/weather/alert",
              method: "get",
            },
          }}
        >
          {(formProps) => (
            <>
              <div className="c-input">
                <MapPolygonInput
                  params={{
                    id: "location",
                    editDisabled: false,
                    validations: [
                      {
                        type: "boundaryValidation",
                      },
                      {
                        type: "required",
                      },
                    ],
                    previewForecasts: false,
                    mapTimeSettings: {
                      date,
                      setDate,
                      time,
                      setTime,
                    },
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <TextInput
                  params={{
                    id: "title",
                    editDisabled: false,
                    validations: [
                      {
                        type: "required",
                      },
                      {
                        type: "maxLength",
                        value: 100,
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    showTextLengthCounter: true,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <RadioGroupInput
                  params={{
                    id: "id_alert",
                    editDisabled: false,
                    setIconField: false,
                    options: alertOptions,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <RadioGroupInput
                  params={{
                    id: "id_level",
                    editDisabled: false,
                    setIconField: false,
                    options: levelOptions,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <TextAreaInput
                  params={{
                    id: "description",
                    editDisabled: false,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
            </>
          )}
        </CustomForm>
      </PermissionsControl>
    </div>
  );
}

export default AlertsForm;
