import { PatternFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

import IconSelector from "../IconSelector";

const getLengthValue = (validations) => {
  const lengthValidation = validations.filter(
    (rule) => rule.type === "maxLength"
  );

  if (lengthValidation.length > 0) {
    return lengthValidation[0].value;
  }

  return null;
};

function Input({ params }) {
  const { t } = useTranslation();
  const {
    id,
    formType,
    mask,
    formProps,
    isEditing,
    editDisabled,
    validations,
  } = params;

  if (mask) {
    //numeric input with mask//
    return (
      <PatternFormat
        id={id}
        name={id}
        value={formProps.values[id]}
        className={
          formProps.errors[id] && formProps.touched[id]
            ? "input input--error"
            : "input"
        }
        onValueChange={(values) => {
          const { formattedValue, value } = values;
          if (mask.unformatValue) {
            formProps.setFieldValue(id, value);
          } else {
            formProps.setFieldValue(id, formattedValue);
          }
        }}
        valueIsNumericString={mask.unformatValue}
        onBlur={formProps.handleBlur}
        format={mask.format}
        mask={mask.placeholder}
        disabled={formProps.isSubmitting || (isEditing && editDisabled)}
        placeholder={t(`${formType}.fieldPlaceholder.${id}`)}
      />
    );
  } else {
    //regular input//
    return (
      <input
        id={id}
        name={id}
        className={
          formProps.errors[id] && params.formProps.touched[id]
            ? "input input--error"
            : "input"
        }
        value={formProps.values[id]}
        onChange={formProps.handleChange}
        onBlur={formProps.handleBlur}
        disabled={formProps.isSubmitting || (isEditing && editDisabled)}
        placeholder={t(`${formType}.fieldPlaceholder.${id}`)}
        maxLength={getLengthValue(validations)}
      />
    );
  }
}

function TextInput({ params }) {
  const { t } = useTranslation();
  const { id, formType, validations, formProps, showTextLengthCounter } =
    params;

  return (
    <div className="form-content-component">
      <label className="colored-label">
        {t(`${formType}.fields.${id}`)}
        {validations.filter((rule) => rule.type === "required").length > 0 &&
          "*"}
      </label>
      <Input params={params} />
      {showTextLengthCounter && (
        <div className="c-text-counter">
          <span className="counter-text">
            {t("common.form.counterText", {
              value: getLengthValue(validations) - formProps.values[id].length,
            })}
          </span>
        </div>
      )}
      <div
        className={
          formProps.errors[id] && formProps.touched[id]
            ? "c-error-text"
            : "c-error-text c-error-text--hidden"
        }
      >
        <IconSelector svg={"ErrorWarningLine"} classname={"icon--warning"} />
        <span className="error-text">{formProps.errors[id]}</span>
      </div>
    </div>
  );
}

export default TextInput;
