import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { Navigate } from "react-router-dom";

import { pageview } from "../utils/googleAnalytics";
import Login from "./Login";
import PrivateRoutes from "./PrivateRoutes";
import SetPasswordPage from "./SetPasswordPage";
import TermsAndPrivacyPt from "./TermsAndPrivacyPt";
import Home from "./Home";
import NwpForecast from "./NwpForecast";
import ForecastDetails from "./ForecastDetails";
import UsersForm from "./forms/UsersForm";
import ForecastsForm from "./forms/ForecastsForm";
import AlertsForm from "./forms/AlertsForm";
import TenantSelection from "./TenantSelection";
import ErrorPage from "./ErrorPage";
import UserProfile from "./UserProfile";
import "../../css/styles.scss";
import AlertTable from "./tables/AlertTable";
import ForecastTable from "./tables/ForecastTable";
import UserTable from "./tables/UserTable";
import MobileStoreRedirect from "./MobileStoreRedirect";
import RainReportTable from "./tables/RainReportTable";
import TermsAndPrivacyEn from "./TermsAndPrivacyEn";

function App() {
  let location = useLocation();

  useEffect(() => {
    pageview(location.pathname, document.title);
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path="nwpForecast" element={<NwpForecast />} />
          <Route
            path="forecasts"
            element={<ForecastTable key={"forecasts"} />}
          />
          <Route path="alerts" element={<AlertTable key={"alerts"} />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="users" element={<UserTable key={"users"} />} />
          <Route path="forecasts/details/:id" element={<ForecastDetails />} />
          <Route path="users/create" element={<UsersForm />} />
          <Route path="users/edit/:itemId" element={<UsersForm />} />
          <Route path="forecasts/create" element={<ForecastsForm />} />
          <Route path="forecasts/edit/:itemId" element={<ForecastsForm />} />
          <Route path="alerts/create" element={<AlertsForm />} />
          <Route path="alerts/edit/:itemId" element={<AlertsForm />} />
          <Route
            path="rain-occurrences"
            element={<RainReportTable key={"rainReport"} />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/select-tenant" element={<TenantSelection />} />
        <Route path="/recovery" element={<SetPasswordPage type="recovery" />} />
        <Route
          path="/activate/:token"
          element={<SetPasswordPage type="activate" />}
        />
        <Route path="/terms/en" element={<TermsAndPrivacyEn type="terms" />} />
        <Route
          path="/privacy/en"
          element={<TermsAndPrivacyEn type="privacy" />}
        />
        <Route path="/terms" element={<TermsAndPrivacyPt type="terms" />} />
        <Route path="/privacy" element={<TermsAndPrivacyPt type="privacy" />} />
        <Route path="/store/*" element={<MobileStoreRedirect />} />
        <Route
          path="/404"
          element={<ErrorPage params={{ type: "pageNotFound" }} />}
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </StyledEngineProvider>
  );
}

export default App;
